import React from "react";

import {
	createUseStyles
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

// import createPersistedState from "use-persisted-state";


import useSavedHomes from "hooks/useSavedHomes";


import ButtonGroup from "./ButtonGroup";
import ButtonOutline from "./ButtonOutline";
import ButtonCircle from "components/ButtonCircle";
import UnitInfoLine from "./UnitInfoLine";


import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";
import QueryData from "../data/QueryData";
// import ConstantData from "data/ConstantData";


// import saveHomePlusLight from "../img/save_home_plus_light.svg";
import plusIcon from "img/icon_plus.svg";



// const useSavedHomesState = createPersistedState( ConstantData.localStorageKeys.savedHomes );



const useStyles = createUseStyles(
	{
		wrapper: {
			width: 330,
			minHeight: 200,

			position: 'absolute',
			right: 100,
			top: 200,

			transition: 'all 2s',

			...ThemeData.styles.hoverCard,
		},
		hidden: {
			display: 'none',
		},
		floorplanImage: {
			minHeight: 190,

			borderBottom: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
		'$floorplanImage, $details': {
			borderBottom: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
		details: {
			position: 'relative',

			minHeight: 63,

			padding: 20,

			textAlign: 'left',

			'& h4': {
				margin: 0,
				padding: 0,

				fontFamily: ThemeData.fonts.gothamBold,
				fontSize: '24px',
			},

			'& p': {
			},
		},
		unitInfoLine: {
			marginTop: 10,

			color: ThemeData.colours.grey,

			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: '12px',
			letterSpacing: '0.05em',
		},
		saveHomePlus: {
			position: 'absolute',
			top: 16,
			right: 20,

			// width: 32,
			// height: 32,

			// background: `center no-repeat url(${saveHomePlusLight})`,
		},
		buttonArea: {
			paddingInline: 20,
			paddingBlockEnd: 20,

			borderBottom: `1px ${ThemeData.colours.eggshellTextBg} solid`,
		},
	},
	{
		name: "UnitDetailCard",
	}
)


// function toggleSavedHome( hooks, unitId ) {
// 	let prevUnitIdExistsIdx = hooks.savedHomes.findIndex(elm => elm.id == unitId);
// 	if (prevUnitIdExistsIdx === -1)
// 		hooks.setSavedHomes([...hooks.savedHomes, { id: unitId }]);
// 	else {
// 		let newSavedHomes = hooks.savedHomes;
// 		newSavedHomes.splice(prevUnitIdExistsIdx, 1);
// 		hooks.setSavedHomes([...newSavedHomes]);
// 	}
// }


function renderUnitDetailCard( selectedUnitId, data, classes, error, toggleUnitDetailPaneFcn, hooks, props ) {
	const doDisplayCard = !( selectedUnitId === -1 );

	const apiUri = NetData.apiUri;

	return (
		<div className={`${classes.wrapper} ${doDisplayCard ? "" : classes.hidden}`}>
			<div className={classes.floorplanImage}>
				{data && data.unit && data.unit.unit_plan_type && data.unit.unit_plan_type.image &&
					<img
						src={`${NetData.imgUri( data.unit.unit_plan_type.image.formats && data.unit.unit_plan_type.image.formats.small ?
							data.unit.unit_plan_type.image.formats.small.url :
							data.unit.unit_plan_type.image.url )} `}
						alt={data.unit.unit_plan_type.image.alternativeText}
						width={331}
						height={187}
					/>
				}
				{( !data || !data.unit || !data.unit.unit_plan_type || !data.unit.unit_plan_type.image ) &&
					<img
						src={`${apiUri}/uploads/floorplan_placeholder_85dd01fc30.png`}
						alt='floorplan placeholder'
						width={331}
						height={187}
					/>
				}
			</div>
			<div className={classes.details}>
				<ButtonCircle 
					className={classes.saveHomePlus}
					src={plusIcon}
					alt="Add Floorplan To Saved"
					widthHeight={32}
					iconWidthHeight={20}
					textColour={ThemeData.colours.clay25}
					borderColour={ThemeData.colours.grey25}
					onClick={() => {
						if (data && data.unit) {
							hooks.toggleSavedHome( data.unit.id );
						}
					}}
				/>
				<h4>{data && data.unit && `Plan ${data.unit.unit_plan_type.name}`}</h4>
				<div className={classes.unitInfoLine}>{data && data.unit && <UnitInfoLine
					data={data.unit}
				/>}</div>
			</div>
			<div className={classes.buttonArea}>
				<ButtonGroup>
					<ButtonOutline
						label="Enjoy the View"
						// padding={}
						textColour={ThemeData.colours.clay}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						to="."
						onClick={props.toggleViewStudyPaneFcn}
						firstInColl
					/>
					<ButtonOutline
						label="Take the Tour"
						// padding={20}
						textColour={ThemeData.colours.clay}
						hoverTextColour={ThemeData.colours.white}
						borderColour={ThemeData.colours.grey25}
						onClick={props.toggleVirtualTourPaneFcn}
						to="."
					/>
				</ButtonGroup>
			</div>
			<div className={classes.moreDetailsButtonArea}>
				<ButtonOutline
					label="More Details"
					padding={20}
					textColour={ThemeData.colours.clay}
					hoverTextColour={ThemeData.colours.white}
					border="none"
					backgroundColour={ThemeData.colours.eggshellTextBg}
					onClick={toggleUnitDetailPaneFcn}
				/>
			</div>
		</div>
	);
}


function UnitDetailCard( { selectedUnitId, toggleUnitDetailPaneFcn, ...props } ) {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();

	// console.log( "saved", savedHomes );

	let hooks = {
		savedHomes,
		toggleSavedHome
	}


	const { loading, error, data } = useQuery( QueryData.unitById, {
		variables: {
			unitId: selectedUnitId
		}
	} );


	if ( loading ) return renderUnitDetailCard( selectedUnitId, null, classes, null, toggleUnitDetailPaneFcn, hooks, props);
	if ( error ) return renderUnitDetailCard( selectedUnitId, null, classes, error, toggleUnitDetailPaneFcn, hooks, props );

	// console.log( data );

	return renderUnitDetailCard( selectedUnitId, data, classes, null, toggleUnitDetailPaneFcn, hooks, props );
}



export default UnitDetailCard;