import {
	gql,
} from "@apollo/client";



const QueryData = {
	unitById: gql`
		query units( $unitId: ID! ) {
			unit (id: $unitId) {
				id
				name
				unit_number
				interior_sqft
				exterior_sqft
				baths
				is_typical_plan
				info_line_override
				floorplan_image {
					url
					alternativeText
					formats
				}
				keyplan_image {
					url
					alternativeText
					formats
				}
				unit_floor {
					id
					name
					number
				}
				unit_building {
					id
					name
				}
				unit_home_type {
					name
					number_beds
					text_beds
				}
				unit_plan_type {
					id
					name
					floorplan_image {
						url
						alternativeText
						formats
					}
					keyplan_image {
						url
						alternativeText
						formats
					}
				}
				unit_exposures {
					direction
				}
			}
		}
	`,
	unitsQueryText: `
		units {
			id
			name
			unit_number
			interior_sqft
			exterior_sqft
			baths
			is_typical_plan
			info_line_override
			floorplan_image {
				url
				alternativeText
				formats
			}
			keyplan_image {
				url
				alternativeText
				formats
			}
			unit_floor {
				id
				name
				number
			}
			unit_building {
				id
				name
			}
			unit_home_type {
				id
				name
				number_beds
				text_beds
			}
			unit_plan_type {
				id
				name
				floorplan_image {
					url
					alternativeText
					formats
				}
				keyplan_image {
					url
					alternativeText
					formats
				}
			}
			unit_exposures {
				id
				direction
			}
		}
	`,
	neighbourhoodLocations: gql`
		query neighbourhoodLocations {
			neighbourhoodLocations {
				id
				number
				name
				latitude
				longitude
				street_address
				city
				province_short
				postal_code
				neighbourhood_location_category {
					  name
					  colour
					  is_colour_outline
					  do_show_in_list
				}
			}
		}
	`,
	neighbourhoodLocationCategories: gql`
		query neighbourhoodLocationCategories {
			neighbourhoodLocationCategories( where: {
				do_show_in_list: "true"
			} ) {
				id
				name
				order
				colour
				is_colour_outline
				do_show_in_list
				neighbourhood_locations {
					id
					number
					name
					latitude
					longitude
					street_address
					city
					province_short
					postal_code
				}
			}
		}
	`,
	galleryPhotosQueryText: `
			name
			image {
				url
				alternativeText
				width
				height
				formats
			}
			gallery_photo_categories {
				name
				do_display_in_main_gallery
			}
	`,
	// galleryPhotos: gql`
	// 	query galleryPhotos {
	// 		galleryPhotos {
	// 			name
	// 			image {
	// 				url
	// 				alternativeText
	// 				width
	// 				height
	// 				formats
	// 			}
	// 		}
	// 	}
	// `,
};

QueryData.units = gql`
	query units {
		${QueryData.unitsQueryText}
	}
`;

QueryData.unitProperties = gql`
	query unitProperties {
		${QueryData.unitsQueryText}
		unitFloors {
			id
			name
			number
			units {
				id
				unit_number
			}
		}
		unitBuildings {
			id
			name
			units {
				id
				unit_number
			}
		}
		unitHomeTypes {
			id
			name
			number_beds
			text_beds
			units {
				id
				unit_number
			}
		}
		unitPlanTypes {
			id
			name
			floorplan_image {
				url
				alternativeText
				formats
			}
			keyplan_image {
				url
				alternativeText
				formats
			}
			units {
				id
				unit_number
			}
		}
		unitExposures {
			id
			direction
			directionAbbrev
			units {
				id
				unit_number
			}
		}
	}
`;

QueryData.galleryPhotosMainGallery = gql`
	query GalleryPhotos {
		galleryPhotos( where: { gallery_photo_categories: { do_display_in_main_gallery: true } } ) {
			${QueryData.galleryPhotosQueryText}
		}
	}
`;

QueryData.galleryPhotos = gql`
	query GalleryPhotos {
		galleryPhotos {
			${QueryData.galleryPhotosQueryText}
		}
	}
`;

QueryData.galleryPhotosOfCategory = gql`
	query GalleryPhotos( $categoryName: String! ) {
		galleryPhotos( where: { gallery_photo_categories: { name: $categoryName } } ) {
			${QueryData.galleryPhotosQueryText}
		}
	}
`;

QueryData.galleryPhotosTest = gql`
		query galleryPhotos {
			galleryPhotos {
				name
				image {
					url
					alternativeText
					width
					height
					formats
				}
				gallery_photo_categories( where: { do_display_in_main_gallery: true } ) {
					name
					do_display_in_main_gallery
				}
			}
		}
	`;

export default QueryData;