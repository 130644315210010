const ConstantData = {
	contentCardForms: {
		oneUp: "oneUp",
		twoUpHoriz: "twoUpHoriz",
		twoUpVert: "twoUpVert",
		fourUp: "fourUp",
		sixUp: "sixUp",
	},
	contentPageCustomFunctions: {
		interactiveMap: "interactiveMap",
		photoGallery: "photoGallery",
	},
	sectionCustomFunctions: {
		proxima: "proxima",
		floorplans: "floorplans",
		gallery: "gallery",
		map: "map",
	},
	webglViewTypes: {
		byFloorplateDetailed: "byFloorplateDetailed",
		byFloorplateSimple: "byFloorplateSimple",
		building3DModel: "building3DModel",
	},
	webglWindowMessageTypes: {
		webglHotspotClicked: "webglHotspotClicked",
	},
	localStorageKeys: {
		savedHomes: "savedHomes",
		registration: "registration",
		unitsFilter: "unitsFilter",
	},
	gqlQueryToSubqueryTypesMap: {
		unitFloors: "unit_floor",
		unitBuildings: "unit_building",
		unitHomeTypes: "unit_home_type",
		unitPlanTypes: "unit_plan_type",
		unitExposures: "unit_exposures",
	}
};

export default ConstantData;