import React, {

} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	// motion
} from "framer-motion";


import ThemeData from "data/ThemeData";
import NetData from "data/NetData";
import UnitInfoPlanNameTitle from "./UnitInfoPlanNameTitle";
import UnitInfoLine from "./UnitInfoLine";
import ButtonOutline from "./ButtonOutline";


import xClaySmall from "img/x_clay_small.svg";



const useStyles = createUseStyles(
	{
		wrapper: {
			height: 100,

			display: 'flex',
			boxSizing: 'border-box',
			alignItems: 'center',

			color: ThemeData.colours.green,

			padding: '10px 0 10px 0',

			textAlign: 'left',

			borderBottom: `1px ${ThemeData.colours.white50} solid`,
		},
		floorplanImg: {
			flex: '0 0 auto',

			width: 105,
			height: 95,

			marginRight: 25,
			borderRadius: 4,

			backgroundColor: ThemeData.colours.white,

			'& > img': {
				width: 105,
				height: 95,

				objectFit: 'contain',
			},
		},
		info: {
			flex: '1 0 auto',
		},
		unitInfoPlanNameTitle: {
			marginTop: 0,
		},
		unitInfoLine: {
			color: ThemeData.colours.green,

			textTransform: 'uppercase',
		},
		removeHomeButton: {
			flex: '0 0 auto',
			
			width: 48,
		},
	},
	{
		name: "UnitDetailListItemWide",
	}
)



const UnitDetailListItemWide = ( { isActive, data, ...props } ) => {

	const classes = useStyles();

	// const apiUri = NetData.apiUri;

	return (
		<div className={classes.wrapper}>
			<div className={classes.floorplanImg}>
				{data.floorplan_image && <img
					src={`${NetData.imgUri( data.floorplan_image.formats && data.floorplan_image.formats.small.url )}`}
					alt={data.floorplan_image.alternativeText}
				/>}
			</div>
			<div className={classes.info}>
				<UnitInfoPlanNameTitle
					data={data}
					color={ThemeData.colours.clay}
					fontSize={20}
					className={classes.unitInfoPlanNameTitle}
				/>
				<UnitInfoLine
					data={data}
					className={classes.unitInfoLine}
				/>
			</div>
			<div className={classes.removeHomeButton}>
				<ButtonOutline
					src={xClaySmall}
					alt="Toggle This Saved Home"
					textColour={ThemeData.colours.green}
					hoverTextColour={ThemeData.colours.clay}
					backgroundColour={ThemeData.colours.white}
					hoverBackgroundColour={ThemeData.colours.clay25}

					borderColour={ThemeData.colours.white25}
					onClick={() => props.toggleSavedHome()}

					label="+ Add"
					activeLabel="Remove"
					isActive={isActive}
				/>
			</div>
		</div>
	);
}



export default UnitDetailListItemWide;