import { useQuery, gql } from "@apollo/client";

import {
	Link,
	useLocation,
	useRouteMatch,
} from "react-router-dom";

import {
	toHtml,
	icon,
} from "@fortawesome/fontawesome-svg-core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

// import { ThemeContext } from '../theme-context';
import ThemeData from '../data/ThemeData';
import { createUseStyles } from "react-jss";

import SlugData from '../data/SlugData';

// import navArrowLeft from '../img/nav_arrow_left.svg';
// import navArrowLeftFallback from '../img/nav_arrow_left.png';
// import navArrowRight from '../img/nav_arrow_right.png';


const navQuery = gql`
	query NavItems( $location: String! ) {
		navItems(where: {
			nav: {
				slug: $location
			}
		} ) {
			id
			order
			alt_name
			section {
				name
				slug
				custom_override_url
			}
		}
	}
`;

const useStyles = createUseStyles( {
	nav: {
		margin: 0,
		padding: 0,

		fontFamily: ThemeData.fonts.gothamBold,

		display: 'flex',
		'& >': {
			flex: '0 1 auto',
		},

		'& ul': {
			margin: 0,
			padding: 0,

			height: 100,

			flex: '1 1 auto',

			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-evenly',
			// alignItems: 'center',

			'& li': {
				// height: '100%',
				width: '20%',

				flex: '1 1 auto',

				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',

				// borderRight: '1px rgb( 255, 255, 255, .5 ) solid',

				listStyleType: 'none',

				opacity: .5,

				'& a': {
					height: 22,

					display: 'block',

					flex: '0 1 auto',

					color: `${ThemeData.colours.eggshellTextBg}`,

					textDecoration: 'none',
					lineHeight: '22px',

					borderLeft: `2px ${ThemeData.colours.thinBorder} solid`,
				},
				'&.firstItem a': {
					borderLeft: 'none',
				},
				'& .currentUnderline': {
					position: 'relative',
					display: 'none',
					top: 10,
					width: 40,
					borderTop: `4px ${ThemeData.colours.navCurrentUnderline} solid`,
					margin: '-4px auto 0 auto',
					padding: 0,
				},
				'&.currentItem .currentUnderline': {
					display: 'block',
				}
			},
			'& li.currentItem': {
				opacity: 1,
			},
			
		},
	},
	arrowEnclosure: {
		display: 'flexbox',
	},
	arrow: {
		height: 100,
		width: 100,

		transition: 'all 0.66s ease',

		cursor: 'pointer',

		'& .imageHolder': {
			width: '100%',
			height: '100%',
		},

		'&.arrowDisabled': {
			cursor: 'default',

			background: 'rgba( 0, 0, 0, 0 )',

			'& .imageHolder': {
				opacity: .4,
			},
		},
	},
	leftArrow: {
		borderRight: `1px ${ThemeData.colours.thinBorder} solid`,

		...ThemeData.ux.fillToLeft,

		'& .imageHolder': {
			// background: `url('${navArrowLeft}') no-repeat center`,
			// background: `url('${faArrowLeft}') no-repeat center`,
			background: `url('data:image/svg+xml;base64,${btoa( toHtml( icon( faArrowLeft ).abstract[0] ) )}') no-repeat center`,
			backgroundSize: 25,
		},
	},
	rightArrow: {
		borderLeft: `1px ${ThemeData.colours.thinBorder} solid`,

		...ThemeData.ux.fillToRight,

		'& .imageHolder': {
			background: `url('data:image/svg+xml;base64,${btoa( toHtml( icon( faArrowRight ).abstract[0] ) )}') no-repeat center`,
			backgroundSize: 25,
		},
	},
} );

function Nav( { navLocation, navItems, fullPassedNavItemCount } ) {

	const classes = useStyles();
	const location = useLocation();
	const matchUri = useRouteMatch();
	const currentLocationPathname = SlugData.determineCurrentLocation( location.pathname );

	const { loading, error, data } = useQuery( navQuery, {
		variables: {
			location: navLocation
		}
	} );

	if ( navLocation ) {
		// If called by query to navLocation

		if ( loading ) return <p></p>;
		if ( error ) return (
			<p>{console.log( 'n', error.message )}</p>
		);

		navItems = data.navItems;
	} else {
		// If called by passing navItems
	}

	// console.log( data );
	// console.log( 'navLocation:', navLocation );

	// var currentLocationPathname = location.pathname.slice( 1, location.pathname.length );
	// if ( !currentLocationPathname ) {
	// 	currentLocationPathname = SlugData.HOME_SLUG_NAME;
	// }

	// console.log( 'location pathname', currentLocationPathname );
	// console.log( data.navItems );
	// data.navItems.map( ( navItem ) => {
	// 	console.log( navItem );
	// } );

	function genSectionLink( section ) {
		if ( section.custom_override_url )
			return `/${section.custom_override_url}`;

		return "/" + ( section.slug === SlugData.HOME_SLUG_NAME ? "" : section.slug );
	}

	let navItemsHere = data.navItems.slice().sort( ( itemA, itemB ) => itemA.order - itemB.order );
	// console.log( 'navI', navItemsHere );
	// navItemsHere = navItemsHere.slice().sort( ( itemA, itemB ) => itemA - itemB );
	let currentNavItemIdx = navItemsHere.findIndex( ( navItem ) => navItem.section.slug === currentLocationPathname );
	let prevNavItemIdx =
		currentNavItemIdx === 0 ?
			null :
			currentNavItemIdx - 1;
	let nextNavItemIdx =
		currentNavItemIdx === navItemsHere.length - 1 ?
			null :
			currentNavItemIdx + 1;

	if ( currentNavItemIdx === -1 ) { // Index not found, so slug isn't indicative of section 
		prevNavItemIdx = null;
		nextNavItemIdx = null;
	}

	// console.log( navItemsHere );

	// console.log( "curr:", currentNavItemIdx, "prev:", prevNavItemIdx, "next:", nextNavItemIdx );

	return (
		<nav id={navLocation} className={classes.nav}>
			{/* <Link to={prevNavItemIdx != null ? genSectionLink( navItemsHere[prevNavItemIdx].section ) : "."} >
				<div className={`${classes.arrow} ${classes.leftArrow} ${prevNavItemIdx != null ? "" : "arrowDisabled"}`}>
					<div className='imageHolder' />
				</div>
			</Link> */}
			<ul>
				{navItemsHere.map( ( navItem, idx ) => {
					return (
						<li
							className={`${idx === 0 ? 'firstItem' : ''} ${idx === data.navItems.length - 1 ? 'lastItem' : ''} ${currentLocationPathname === navItem.section.slug ? 'currentItem' : ''}`}
							key={navItem.id}
						>
							<Link to={genSectionLink( navItem.section )}>{( navItem.alt_name ? navItem.alt_name : navItem.section.name ).toUpperCase()}</Link>
							<div className='currentUnderline'></div>
						</li>
					)
				} )
				}
			</ul>
			{/* <Link to={nextNavItemIdx ? genSectionLink( navItemsHere[nextNavItemIdx].section ) : matchUri.url} >
				<div className={`${classes.arrow} ${classes.rightArrow} ${nextNavItemIdx ? "" : "arrowDisabled"}`}>
					<div className='imageHolder' />
				</div>
			</Link> */}
		</nav>
	);
	
}

export default Nav;
