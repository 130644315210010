// import logo from './logo.svg';
// import './App.css';

import React, {
	useState,
} from "react";

import {
	useLocation,
	// Switch,
	// Route,
	// useHistory
} from "react-router-dom";

import { createUseStyles } from "react-jss";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion,
} from "framer-motion";


import Nav from './Nav';
import HeaderContainer from './HeaderContainer';
import Footer from './Footer';

import SavedHomesSlideout from "./SavedHomesSlideout";
import SavedHomesCompare from "./SavedHomesCompare";
import Register from "./Register";

import SlugData from '../data/SlugData';

// import pageBg from "../img/overview_bg_crop4.jpg";



const useStyles = createUseStyles(
	{
	wrapperWrapper: {
		height: '100%',
	},
	wrapper: {
		position: 'relative',

		height: '100%',

		display: 'flex',
		flexDirection: 'column',

		// color: '#EEEDE6',
		fontFamily: 'Gotham Medium',

	},
	bgBlack: {
		// backgroundColor: 'black',
	},
	bgWrapper: {
		height: '100%',

		// backgroundImage: `url( ${pageBg} )`,
		backgroundImage: `url( https://storage.googleapis.com/proxima-danielsmpv-prod/MPV_Aerial_Final_resized_47631efec5/MPV_Aerial_Final_resized_47631efec5.jpg?215291.5 )`,
		backgroundRepeat: 'no-repeat',
		// TODO: Change the background position to be centred
		backgroundPosition: '0 -75px',
		backgroundAttachment: 'fixed',
		backgroundSize: 'cover',
	},
	bgDarker: {
		opacity: .5,
	},
	bgNone: {
		backgroundImage: 'none',
	},
	equalSized: {
		position: 'absolute',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	},
	middle: {
		flex: '1 1 auto',
	},
	},
	{
		name: 'Layout', 
	}
);



function RenderLayout( data, props, children ) {


	const classes = useStyles();
	const location = useLocation();
	const currentLocationPathname = SlugData.determineCurrentLocation( location.pathname );

	// const [homeBgImgUrl, setHomeBgImgUrl] = useState( null );

	const [isSavedHomesSlideoutOpen, setIsSavedHomesSlideoutOpen] = useState( false );
	const toggleIsSavedHomesSlideoutOpen = () => setIsSavedHomesSlideoutOpen( !isSavedHomesSlideoutOpen );

	const [isSavedHomesCompareOpen, setIsSavedHomesCompareOpen] = useState( false );
	const toggleIsSavedHomesCompareOpen = () => setIsSavedHomesCompareOpen( !isSavedHomesCompareOpen );

	const [isRegisterOpen, setIsRegisterOpen] = useState( false );
	const toggleIsRegisterOpen = () => setIsRegisterOpen( !isRegisterOpen );

	return (
		<div className={classes.wrapperWrapper}>
			<div className={`${classes.equalSized} ${classes.bgBlack}`} />
			{/* <div className={`${classes.equalSized} ${classes.bgWrapper} ${currentLocationPathname != SlugData.HOME_SLUG_NAME ? classes.bgDarker : ""}`} /> */}
			<div className={`${classes.equalSized} ${classes.bgWrapper} ${currentLocationPathname !== SlugData.HOME_SLUG_NAME ? classes.bgNone : ""}`} />
			<div className={`${classes.wrapper}`}>
				<HeaderContainer 
					toggleIsSavedHomesSlideoutOpen={() => toggleIsSavedHomesSlideoutOpen()}
					toggleIsRegisterOpen={() => toggleIsRegisterOpen()}
					/>
				<div className={classes.middle}>
					{children}
				</div>
				<Footer>
					<Nav navLocation="bottomNav" />
				</Footer>
				{/* <SavedHomesSlideout 
					isOpen={isSavedHomesSlideoutOpen}
					toggleIsOpen={() => toggleIsSavedHomesSlideoutOpen()}
					toggleIsSavedHomesCompareOpen={() => toggleIsSavedHomesCompareOpen()}
					toggleIsRegisterOpen={toggleIsRegisterOpen}
				/>
				<SavedHomesCompare
					isOpen={isSavedHomesCompareOpen}
					toggleIsOpen={() => toggleIsSavedHomesCompareOpen()}
					toggleIsSavedHomesSlideoutOpen={() => toggleIsSavedHomesSlideoutOpen()}
					toggleIsRegisterOpen={toggleIsRegisterOpen}
				/>
				<Register
					doShow={isRegisterOpen}
					toggleIsOpen={() => toggleIsRegisterOpen()}
				/> */}
			</div>
		</div>
	);
}



function Layout( { children, ...props } ) {

	let data = null;

	return RenderLayout( data, props, children );

}



export default Layout;
