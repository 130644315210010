import React, {
	// useState,
	// useRef,
	// useEffect,
	useCallback,
} from "react";

import {
	// useQuery,
	// gql,
} from "@apollo/client";

import {
	createUseStyles,
} from "react-jss";

import {
	motion,
} from "framer-motion";

import useRegistration from "hooks/useRegistration";

import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";


import ThemeData from "../data/ThemeData";
// import QueryData from "data/QueryData";


// import navArrowLeftDark from "../img/nav_arrow_left_dark.svg";
// import mapMarkerAmp from "img/map_marker_amp.svg";
import xWhiteMedium from "img/x_white_medium.svg";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'fixed',
			top: '50%',
			left: '50%',

			padding: 30,

			width: 750,
			height: 380,

			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.green,

			textAlign: 'left',

			...ThemeData.styles.hoverPaneBoxShadow,
		},
		closeX: {
			position: 'absolute',
			top: 20,
			right: 20,

			cursor: 'pointer',
		},
		title: {
			fontFamily: ThemeData.fonts.gothamBook,

			fontSize: 30,
		},
		label: {
			display: 'inline-block',
			marginTop: 40,

			fontFamily: ThemeData.fonts.gothamMedium,
			fontSize: 10,
			textTransform: 'uppercase',
		},
		input: {
			width: 300,

			border: 'none',
			borderBottom: `1px ${ThemeData.colours.clay} solid`,

			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.green,

			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 16,

			"&:focus, &:active": {
				outline: 'none',

				border: 'none',
				borderBottom: `1px ${ThemeData.colours.clay} solid`,
			},
		},
		firstName: {
			// position: 'absolute',
			// top: 50,
			// left: 50,
		},
		lastName: {
			paddingLeft: 50,
			// position: 'absolute',
			// top: 50,
			// left: 400,
		},
		email: {
			// position: 'absolute',
			// top: 150,
			// left: 50,
		},
		submitButton: {
			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 16,
		},
	},
	{
		name: "Register",
	}
)



const SubmitButton = styled( Button )( {

	marginTop: 50,

	color: ThemeData.colours.white,
	border: `1px ${ThemeData.colours.white} solid`,

	fontFamily: ThemeData.fonts.gothamBook,
	fontSize: 16
	// backgroundColor: purple[500],
	// '&:hover': {
	// 	backgroundColor: purple[700],
	// },
} );



function RenderRegister( classes, data, error, doShow, props ) {

	const { registration, setFirstName, setLastName, setEmail } = useRegistration();

	const handleSubmit = useCallback( e => {
		console.log( "submit", registration.fName, registration.lName, registration.email );

		e.preventDefault();
	}, [registration] );


	return (
		<motion.div
			className={classes.wrapper}
			initial={false}
			animate={doShow ? "show" : "dontShow"}
			variants={{
				show: {
					x: '-50%',
					y: '-50%',
					zIndex: 4,
					display: 'block',
				},
				dontShow: {
					x: "100%",
					y: '-50%',
					display: 'block',
					transitionEnd: {
						zIndex: -2,
						display: 'none',
					}
				},
			}}
			transition={{
				duration: .5,
			}}
		>
			<div
				className={classes.closeX}
				onClick={() => props.toggleIsOpen()}>
				{/* <img src={chevronLeftWhiteMedium}  */}
				<img src={xWhiteMedium} alt="Slide Away Saved Homes" />
			</div>
			<div className={classes.title}>
				Send Saved Homes to Email
			</div>
			<form onSubmit={handleSubmit}>
				<label className={`${classes.label} ${classes.firstName}`}>
					First Name:<br /><br />
					<input className={classes.input} value={registration.fName} onChange={evt => setFirstName( evt.target.value )}></input>
				</label>
				<label className={`${classes.label} ${classes.lastName}`}>
					Last Name:<br /><br />
					<input className={classes.input} value={registration.lName} onChange={evt => setLastName( evt.target.value )}></input>
				</label><br />
				<label className={`${classes.label} ${classes.email}`}>
					Email:<br /><br />
					<input className={classes.input} value={registration.email} onChange={evt => setEmail( evt.target.value )}></input>
				</label><br />
				<SubmitButton
					className={classes.submitButton}
					sx={{
						...classes.submitButton,
					}}
					type='submit'
					value='Send Email'
					variant='outlined'
				>
					Send Email
				</SubmitButton>
			</form>
		</motion.div>
	);
}

const Register = ( { doShow, ...props } ) => {

	const classes = useStyles();

	let data = null;

	// const { loading, error, data } = useQuery( QueryData.neighbourhoodLocationCategories );

	// if( loading ) return RenderRegister( classes, null, null, doShow, props );
	// if( error ) return RenderRegister( classes, null, error, doShow, props );

	// console.log( data );

	return RenderRegister( classes, data, null, doShow, props );

};



export default Register;