import {
	useRouteMatch,
	Link,
} from "react-router-dom";

import { createUseStyles } from "react-jss";

import ThemeData from "../data/ThemeData";
import NetData from "../data/NetData";

import contentCardPlus from "../img/content_card_plus.svg";

const useStyles = createUseStyles(
	{
		contentCardItem: {
			position: 'relative',

			flex: '0 0 auto',

			overflow: 'hidden',
			// overflow: 'clip',

			'&:hover $bgImg': {
				transform: 'scale(1.1)',
			},

			'& a, & a:visited': {
				// color: `${ThemeData.colours.eggshellTextBg}`,
				color: `${ThemeData.colours.white}`,
			},
		},
		contentCardTitleContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
		contentCardTitle: {
			flex: '0 1 auto',

			padding: 60,
		},
		contentCardPlus: {
			position: 'absolute',
			right: 0,

			width: 100,
			height: 100,

			background: 'no-repeat center',
			backgroundImage: `url('${contentCardPlus}')`
		},
		oneUp: {
			height: 'calc( 50% - 10px )',
		},
		twoUpHoriz: {
			// flex: '1 1 auto',
			// width: '66%',
			width: '100%'
		},
		twoUpVert: {
			// flex: '1 1 auto',
			// width: '33%',
			height: '100%',
		},
		fourUp: {
			// flex: '1 1 auto',
			width: '100%',
			height: '100%',
		},
		sixUp: {
			width: '100%',
			height: '100%',
		},
		equalSized: {
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
		},
		bgImg: {
			background: `no-repeat center`,
			backgroundSize: 'cover',

			opacity: .5,

			transition: 'all .4s ease',
		},
		bgImgNoDark: {
			opacity: 1,
		},
		bgImgBlackBg: {
			backgroundColor: 'black',
		},
	},
	{
		name: "ContentCard"
	}
);

function ContentCard( { contentPage } ) {

	const classes = useStyles();
	// const apiUri = NetData.apiUri;
	const matchUri = useRouteMatch();

	// console.log( contentPage );
	// console.log( contentPage.image[0] );

	let contentPageUrl = `${matchUri.url}/${contentPage.slug}`;

	if ( contentPage.custom_link ) {
		contentPageUrl = `${contentPage.custom_link}`;
	}

	// Figure out if the Card should be dark with a title over it or not
	let doNotDarkenOrTitleCard = false;
	if ( contentPage.section.slug === "gallery" )
		doNotDarkenOrTitleCard = true;
	if ( contentPage.is_only_image_card )
		doNotDarkenOrTitleCard = true;

	// console.log( contentPage );

	return (
		<div className={`${contentPage.form && classes[contentPage.form.value]} ${classes.contentCardItem}`} key={contentPage.id}>
			<div className={`${classes.equalSized} ${classes.bgImgBlackBg}`}>
			</div>
			<div className={`${classes.equalSized} ${classes.bgImg} ${doNotDarkenOrTitleCard ? classes.bgImgNoDark : ""}`} style={contentPage.image[0] && {
				// backgroundImage: `url(${apiUri}${contentPage.image[0].formats ?
				// 		( contentPage.image[0].formats.large ?
				// 			contentPage.image[0].formats.large.url :
				// 			contentPage.image[0].url ) :
				// 		contentPage.image[0].url})`
				backgroundImage: `url(${NetData.imgUri(
					contentPage.image[0].formats ?
						( contentPage.image[0].formats.large ?
							contentPage.image[0].formats.large.url :
							contentPage.image[0].url ) :
						contentPage.image[0].url )
					}
				)`
			}}>
			</div>
			<Link to={contentPageUrl}>
				<div className={`${classes.equalSized} ${classes.contentCardTitleContainer}`}>
					<div className={classes.contentCardTitle}>
						<p>{doNotDarkenOrTitleCard ? "" : contentPage.title}</p>
					</div>
				</div>
				<div className={classes.contentCardPlus} />
			</Link>
		</div>
	)
}


export default ContentCard;