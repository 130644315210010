import './App.css';

import React, {
	// useEffect,
	useRef,
} from "react";

import {
	Route,
	Switch,
	useLocation,
} from "react-router-dom";

import { createUseStyles } from "react-jss";

import {
	AnimatePresence
} from "framer-motion";

import "mapbox-gl/dist/mapbox-gl.css";


import ThemeData from "./data/ThemeData";
import SlugData from "./data/SlugData";

import Section from "./components/Section";
import Proximation from "./components/Proximation";

import gothamBook from "./fonts/Gotham-Book.woff";
import gothamBookOtf from "./fonts/Gotham-Book.otf";
import gothamBold from "./fonts/Gotham-Bold.woff";
import gothamBoldOtf from "./fonts/Gotham-Bold.otf";
import gothamMedium from "./fonts/Gotham-Medium.woff";
import gothamMediumOtf from "./fonts/Gotham-Medium.otf";

const useStyles = createUseStyles( {
	'@font-face': [
		{
			fontFamily: 'Gotham Book',
			src: `url( ${gothamBook} ) format( woff )`,
			fallbacks: [
				{ src: `url( ${gothamBookOtf} ) format( opentype )` }
			],
		},
		{
			fontFamily: 'Gotham Bold',
			src: `url( ${gothamBold} )`,
			fallbacks: [
				{ src: `url( ${gothamBoldOtf} ) format( opentype )` }
			],
		},
		{
			fontFamily: 'Gotham Medium',
			src: `url( ${gothamMedium} ) format( woff )`,
			fallbacks: [
				{ src: `url( ${gothamMediumOtf} ) format( opentype )` }
			],
		}
	],
	root: {
		height: '100%',

		// color: ThemeData.colours.eggshellTextBg,
		color: "#000000",
		backgroundColor: ThemeData.colours.green,
	}
})

function Root() {

	const location = useLocation();

	const classes = useStyles();

	const proxima = useRef( null );
	// const proxima = useRef( null );
	const proximaContainer = useRef( null );
	// const proximaContainer = useRef( null );

	// useEffect( () => {
	// 	console.log( 'prox:', proxima );
	// }, [proxima] );
	// useEffect( () => {
	// 	console.log( 'proxCon:', proximaContainer );
	// }, [proximaContainer] );

	return (
		<div className={`${classes.root}`}>
			<AnimatePresence>
			<Switch location={location} key={location.pathname}>
				<Route path="/proximation">
						<Proximation proxima={proxima} proximaContainer={proximaContainer}/>
				</Route>
				<Route path="/:slug">
					<Section />
				</Route>
				{/* <Route
					path="/about"
					component={( props ) => (
						<Section slug="about" {...props} />
					)} />
				<Route
					path="/neighbourhood"
					component={( props ) => (
						<Section slug="neighbourhood" {...props} />
					)} />
				<Route
					path="/floorplans"
					component={( props ) => (
						<Section slug="floorplans" {...props} />
					)} />
				<Route
					path="/amenities"
					component={( props ) => (
						<Section slug="amenities" {...props} />
					)} />					<Route
					path="/showhomes"
					component={( props ) => (
						<Section slug="showhomes" {...props} />
					)} />
				<Route
					path="/gallery"
					component={( props ) => (
						<Section slug="gallery" {...props} />
					)} /> */}
				{/* <Route path="/gallery"> */}
					{/* <Route path="/thing"> */}
					{/* <div className="App">
						<header className="App-header">
							<img src={logo} className="App-logo" alt="logo" />
							<p>
								Edit <code>src/App.js</code> and save to reload.
							</p>
							<a
								className="App-link"
								href="https://reactjs.org"
								target="_blank"
								rel="noopener noreferrer"
							>
								Learn React
							</a>
						</header>
					</div>
				</Route> */}
				<Route path="/">
					<Section sectionSlug={SlugData.HOME_SLUG_NAME} />
				</Route>
					<Route path="/404.html">
						<div>
							<h2>404 Error</h2>
							<p>The page you are trying to reach is not here.</p>
						</div>
				</Route>
				{/* <Route path="/about">
					<div>
						<Nav navLocation="bottomNav" />
					</div>
				</Route> */}
			</Switch>
			</AnimatePresence>
			{/* <header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>
					Edit <code>src/App.js</code> and save to reload.
				</p>
				<a
					className="App-link"
					href="https://reactjs.org"
					target="_blank"
					rel="noopener noreferrer"
				>
					Learn React
				</a>
			</header> */}
		</div>
	);
}

export default Root;
