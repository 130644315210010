import React, {
	// useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	motion,
} from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";


import ThemeData from "data/ThemeData";
// import ConstantData from "data/ConstantData";
import QueryData from "data/QueryData";


import useSavedHomes from "hooks/useSavedHomes";


import UnitDetailListItemWide from "./UnitDetailListItemWide";
// import SavedHomesTitle from "./SavedHomesTitle";
import ButtonOutline from "./ButtonOutline";
import ButtonGroup from "./ButtonGroup";


// import chevronLeftWhiteMedium from "img/chevron_left_white_medium.svg";
import xClayMedium from "img/x_clay_medium.svg";
import UnitCardsParent from "./UnitCardsParent";


// import { calculateNormals } from "playcanvas";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'fixed',
			top: '50%',
			left: '50%',

			width: 'calc( 100vw - 60px )',
			height: 'calc( 100vh - 60px )',
			boxSizing: 'border-box',
			overflow: 'clip',

			padding: 25,
			paddingTop: 30,

			// color: ThemeData.colours.green,
			backgroundColor: ThemeData.colours.white,

			letterSpacing: '.025em',
		},
		bgAmp: {
			zIndex: -1,
			position: 'absolute',
			left: -50,
			bottom: -100,

			color: ThemeData.colours.clay08,
			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 500,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',

			width: '100%',
			height: '100%',
		},
		closeX: {
			position: 'absolute',
			top: 20,
			right: 20,

			cursor: 'pointer',
		},
		title: {
			fontSize: 24,
			textAlign: 'left',
			textTransform: 'uppercase',
		},
		top: {
			flex: '0 0 auto',
		},
		filterButton: {
			borderRadius: 50,
		},
		middle: {
			flex: '1 0 auto',

			maxHeight: 'calc( 100vh - 180px )',

			overflowX: 'clip',
			overflowY: 'auto',

			...ThemeData.styles.thinScrollbar,
		},
		bottom: {
			flex: '0 0 auto',

			boxSizing: 'border-box',
			paddingTop: 25,

			height: 130,

			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
		bottomButton: {
			fontSize: 14,
			letterSpacing: '.1em',
		},
		filterCheckboxes: {
			color: ThemeData.colours.white,
		},
	},
	{
		name: "SavedHomesCompareList",
	},
)



const wrapperAnimVariants = {
	open: {
		x: "-50%",
		y: "-50%",
		opacity: 1,
		zIndex: 4,
		display: 'block',
	},
	closed: {
		x: "-50%",
		y: "-50%",
		opacity: 0,
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}



const RenderSavedHomesCompareList = ( classes, data, error, isOpen, toggleIsOpen, hooks, props ) => {

	if ( error ) {
		console.log( "RenderSavedHomesSlideout", error.message );
	}

	function stopPropagation( e ) {
		e.stopPropagation();
	}

	return (
		<motion.div
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: "tween",
				bounce: .25,
				duration: .5,
			}}
			onMouseDown={( e ) => stopPropagation( e )}
			onMouseUp={( e ) => stopPropagation( e )}
		>
			{/* <div className={classes.bgAmp}>
				&amp;
			</div> */}
			<div className={classes.contentContainer}>
				<div className={classes.top}>
					<h4 className={classes.title}>Select Homes To Compare</h4>
					<div
						className={classes.closeX}
						onClick={() => toggleIsOpen()}>
						{/* <img src={chevronLeftWhiteMedium}  */}
						<img src={xClayMedium} alt="Slide Away Homes List" />
					</div>
					{/* <ButtonGroup
						justifyContent={'left'}
					> */}
						{/* <ButtonOutline
							textColour={ThemeData.colours.green}
							hoverTextColour={ThemeData.colours.clay}
							backgroundColour={ThemeData.colours.white}
							hoverBackgroundColour={ThemeData.colours.clay25}
							borderColour={ThemeData.colours.eggshellTextBg}
							className={classes.filterButton}
							onClick={() => {
							}}
							firstInColl

							isActive={true}
						>
							Rooms &nbsp;<FontAwesomeIcon icon={faCaretDown} />
						</ButtonOutline>
						<ButtonOutline
							textColour={ThemeData.colours.green}
							hoverTextColour={ThemeData.colours.clay}
							backgroundColour={ThemeData.colours.white}
							hoverBackgroundColour={ThemeData.colours.clay25}
							borderColour={ThemeData.colours.eggshellTextBg}
							className={classes.filterButton}
							onClick={() => {
							}}
						>
							Orientation &nbsp;<FontAwesomeIcon icon={faCaretDown} />
						</ButtonOutline>
						<ButtonOutline
							textColour={ThemeData.colours.green}
							hoverTextColour={ThemeData.colours.clay}
							backgroundColour={ThemeData.colours.white}
							hoverBackgroundColour={ThemeData.colours.clay25}
							borderColour={ThemeData.colours.eggshellTextBg}
							className={classes.filterButton}
							onClick={() => {
							}}
						>
							Price &nbsp;<FontAwesomeIcon icon={faCaretDown} />
						</ButtonOutline>
						<ButtonOutline
							textColour={ThemeData.colours.green}
							hoverTextColour={ThemeData.colours.clay}
							backgroundColour={ThemeData.colours.white}
							hoverBackgroundColour={ThemeData.colours.clay25}
							borderColour={ThemeData.colours.eggshellTextBg}
							className={classes.filterButton}
							onClick={() => {
							}}
						>
							Space &nbsp;<FontAwesomeIcon icon={faCaretDown} />
						</ButtonOutline>
						<ButtonOutline
							textColour={ThemeData.colours.green}
							hoverTextColour={ThemeData.colours.clay}
							backgroundColour={ThemeData.colours.white}
							hoverBackgroundColour={ThemeData.colours.clay25}
							borderColour={ThemeData.colours.eggshellTextBg}
							className={classes.filterButton}
							onClick={() => {
							}}
						>
							Availability &nbsp;<FontAwesomeIcon icon={faCaretDown} />
						</ButtonOutline>
					</ButtonGroup> */}
				</div>
				<div className={classes.middle}>
					{/* {data && data.units && data.units.map( unit => {

						// let unitIdx = data.units.findIndex( elm => elm.id == savedHomeUnit.id );

						// if ( unitIdx != -1 )
						return <UnitDetailListItemWide
							data={unit}
							key={unit.id}
							toggleSavedHome={() => hooks.toggleSavedHome( unit.id )}
						/>;

						// return <></>;

					}
					)} */}
					<UnitCardsParent
					// dontShowFilterSort
						filterClassName={classes.filterCheckboxes}
					>
						{( className, dataUnit, toggleSavedHome ) => (
							// <UnitDetailCompareCard
							// 	// <UnitDetailCardGeneric
							// 	className={className}
							// 	dataUnit={dataUnit}
							// 	toggleSavedHome={toggleSavedHome}
							// />
							<UnitDetailListItemWide
							className={className} 
							data={dataUnit}
							toggleSavedHome={toggleSavedHome}
						/>
						)}
					</UnitCardsParent>
				</div>
				<div className={classes.bottom}>
					<ButtonGroup className={classes.bottomButtons}>
						<ButtonOutline
							borderColour={ThemeData.colours.white}
							firstInColl
							className={classes.bottomButton}
							onClick={() => {
								props.toggleIsSavedHomesCompareOpen();
								toggleIsOpen();
							}}
						>
							Compare Homes
						</ButtonOutline>
						<ButtonOutline
							borderColour={ThemeData.colours.white}
							className={classes.bottomButton}
						>
							Send To Email
						</ButtonOutline>
					</ButtonGroup>
				</div>
			</div>
		</motion.div>
	)
}



const SavedHomesCompareList = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();
	let hooks = {
		savedHomes,
		toggleSavedHome,
	};


	const { loading, error, data } = useQuery( QueryData.units );

	if ( loading ) return RenderSavedHomesCompareList( classes, null, null, isOpen, toggleIsOpen, hooks, props );
	if ( error ) return RenderSavedHomesCompareList( classes, null, error, isOpen, toggleIsOpen, hooks, props );

	// console.log( "units", data );

	return RenderSavedHomesCompareList( classes, data, null, isOpen, toggleIsOpen, hooks, props );

}



export default SavedHomesCompareList;