import React, {

} from 'react';
import {
	Link
} from 'react-router-dom';

// import {
// 	motion
// } from 'framer-motion';

import {
	createUseStyles
} from 'react-jss';

import ThemeData from '../data/ThemeData';
import HeaderContextData from '../data/HeaderContextData';

// import ashEdwardLogo from "../img/ash_edward_logo_light.svg";
// import devLogo from "img/kindred_logo_darkFill.png"
import devLogo from "img/mpv_logo_greybar_small.png"
// import useSavedHomes from 'hooks/useSavedHomes';
import SavedHomesTitle from './SavedHomesTitle';

const useStyles = createUseStyles( {
	header: {
		// flex: '0 0 auto',
		display: 'flex',
		justifyContent: 'space-between',

		height: 100,

		borderBottom: '1px rgb( 255, 255, 255, .5 ) solid',
	},
	headerSection: {
		flex: '1 0 auto',

		height: 100,
		width: 350,

		// border: '1px #00FFFF solid',
	},
	headerLeft: {
		display: 'flex',
		alignItems: 'center',
	},
	headerMiddle: {
		margin: '0 auto',

		maxHeight: 100,
		background: 'no-repeat center',
		backgroundImage: `url( ${devLogo} )`,
		backgroundSize: 'contain',

		cursor: 'pointer',
	},
	headerRight: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',

		...ThemeData.typography.nameplate,
	},
	savedHomes: {
		flex: '0 0 auto',

		width: 175,

		marginRight: 50,

		cursor: 'pointer',
	},
	savedHomesNumberText: {
		display: 'inline-block',

		margin: '0 5px',

		backgroundColor: ThemeData.colours.green,

		borderRadius: 10,

		'.mainMenuHeader &': {
			backgroundColor: ThemeData.colours.clay,
		},
	},
	register: {
		display: 'none',

		flex: '0 0 auto',

		width: 175,
		lineHeight: '100px',

		...ThemeData.ux.fillToRight,

		borderLeft: `1px ${ThemeData.colours.white50} solid`,

		cursor: 'pointer',

		'& p': {
			margin: 0,
		}
	},
	// prevMainMenuButton: {
	// 	backgroundColor: 'blue',
	// 	'.isOpen &': {
	// 		backgroundColor: 'red',
	// 	},
	// 	'&:hover': {
	// 		backgroundColor: 'purple',
	// 	},
	// },
} );


function HeaderComponents( { children, headerContext, ...props } ) {

	const classes = useStyles();

	// const { savedHomes } = useSavedHomes();

	return (
		<>
			<div className={classes.header}>
				<div className={`${classes.headerSection} ${classes.headerLeft}`} >
					{children}
				</div>
				<Link
					to="/"
					className={`${classes.headerSection} ${classes.headerMiddle}`}>
				</Link>
				<div className={`${classes.headerSection} ${classes.headerRight} ${headerContext && headerContext === HeaderContextData.mainMenuHeaderContext ? 'mainMenuHeader' : ''}`}>
					{/* <div 
					className={`${classes.savedHomes} ${classes.savedHomesText}`}
					onClick={() => props.toggleIsSavedHomesSlideoutOpen()}>
					<p>Saved Homes &nbsp;<span className={classes.savedHomesNumberText}>&nbsp;&nbsp;{savedHomes.length}&nbsp;&nbsp;</span></p>
				</div> */}
					{/* {headerContext !== HeaderContextData.contentPageHeaderContext &&
						<SavedHomesTitle
							className={classes.savedHomes}
							onClick={() => props.toggleIsSavedHomesSlideoutOpen()}
						/>
					} */}
					<div
						className={`${classes.register}`}
						onClick={() => props.toggleIsRegisterOpen()}>
						<p>Register</p>
					</div>
				</div>
			</div>
		</>
	)

}


export default HeaderComponents;