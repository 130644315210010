import React, {
	// useState,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	motion,
} from "framer-motion";


import ThemeData from "data/ThemeData";
// import ConstantData from "data/ConstantData";
import QueryData from "data/QueryData";


import useSavedHomes from "hooks/useSavedHomes";


import UnitDetailListItem from "./UnitDetailListItem";
import SavedHomesTitle from "./SavedHomesTitle";
import ButtonOutline from "./ButtonOutline";
import ButtonGroup from "./ButtonGroup";


// import chevronLeftWhiteMedium from "img/chevron_left_white_medium.svg";
import xWhiteMedium from "img/x_white_medium.svg";


// import { calculateNormals } from "playcanvas";



const useStyles = createUseStyles(
	{
		wrapper: {
			position: "absolute",
			top: 0,
			right: 0,

			width: 450,
			height: "100%",
			boxSizing: 'border-box',
			overflowX: 'clip',

			padding: 25,
			paddingTop: 30,

			color: ThemeData.colours.white,
			backgroundColor: ThemeData.colours.green,
			...ThemeData.styles.hoverPaneBoxShadow,

			letterSpacing: '.025em',
		},
		bgAmp: {
			zIndex: -1,
			position: 'absolute',
			left: -50,
			bottom: -100,

			color: ThemeData.colours.clay08,
			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 500,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',

			width: '100%',
			height: '100%',
		},
		closeX: {
			position: 'absolute',
			top: 20,
			right: 20,

			cursor: 'pointer',
		},
		savedHomesTitle: {
			textAlign: 'left',
		},
		top: {
			flex: '0 0 auto',

			height: 50,
		},
		middle: {
			flex: '1 0 auto',

			maxHeight: 'calc( 100vh - 180px )',

			overflowX: 'clip',
			overflowY: 'auto',
		},
		bottom: {
			flex: '0 0 auto',

			boxSizing: 'border-box',
			paddingTop: 25,

			height: 130,

			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
		bottomButton: {
			fontSize: 14,
			letterSpacing: '.1em',
		},
	},
	{
		name: "SavedHomesSlideout",
	},
)



const wrapperAnimVariants = {
	open: {
		x: 0,
		zIndex: 4,
		display: 'block',
	},
	closed: {
		x: "100%",
		display: 'block',
		transitionEnd: {
			zIndex: -2,
			display: 'none'
		},
	},
}



const RenderSavedHomesSlideout = ( classes, data, error, isOpen, toggleIsOpen, hooks, props ) => {

	if( error ) {
		console.log( "RenderSavedHomesSlideout", error.message );
	}

	function stopPropagation( e ) {
		e.stopPropagation();
	}

	return (
		<motion.div 
			className={classes.wrapper}
			initial={false}
			animate={isOpen ? "open" : "closed"}
			variants={wrapperAnimVariants}
			transition={{
				type: "tween",
				bounce: .25,
				duration: .5,
			}}
			onMouseDown={( e ) => stopPropagation( e )}
			onMouseUp={( e ) => stopPropagation( e )}
		>
			{/* <div className={classes.bgAmp}>
				&amp;
			</div> */}
			<div className={classes.contentContainer}>
				<div className={classes.top}>
					<SavedHomesTitle
						className={classes.savedHomesTitle}
						titleText="Save and Compare"
						numberBgColor={ThemeData.colours.clay}
					/>
					<div
						className={classes.closeX}
						onClick={() => toggleIsOpen()}>
						{/* <img src={chevronLeftWhiteMedium}  */}
						<img src={xWhiteMedium} alt="Slide Away Saved Homes" />
					</div>
				</div>
				<div className={classes.middle}>
					{data && data.units && hooks.savedHomes.map( savedHomeUnit => {

						let unitIdx = data.units.findIndex( elm => elm.id === savedHomeUnit.id );

						if ( unitIdx !== -1 )
							return <UnitDetailListItem
								data={data.units[unitIdx]}
								key={unitIdx}
								toggleSavedHome={() => hooks.toggleSavedHome( savedHomeUnit.id )}
							/>;

						return <></>;

					}
					)}
				</div>
				<div className={classes.bottom}>
					<ButtonGroup className={classes.bottomButtons}>
						<ButtonOutline
							borderColour={ThemeData.colours.white}
							firstInColl
							className={classes.bottomButton}
							onClick={() => {
								props.toggleIsSavedHomesCompareOpen();
								toggleIsOpen();
							}}
						>
							Compare Homes
						</ButtonOutline>
						<ButtonOutline
							borderColour={ThemeData.colours.white}
							className={classes.bottomButton}
							onClick={() => props.toggleIsRegisterOpen()}
						>
							Send To Email
						</ButtonOutline>
					</ButtonGroup>
				</div>
			</div>
		</motion.div>
	)
}



const SavedHomesSlideout = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();
	let hooks = {
		savedHomes,
		toggleSavedHome,
	};


	const {loading, error, data} = useQuery( QueryData.units );    

	if( loading ) return RenderSavedHomesSlideout( classes, null, null, isOpen, toggleIsOpen, hooks, props );
	if( error ) return RenderSavedHomesSlideout( classes, null, error, isOpen, toggleIsOpen, hooks, props );

	// console.log( "units", data );

	return RenderSavedHomesSlideout( classes, data, null, isOpen, toggleIsOpen, hooks, props );
	
}



export default SavedHomesSlideout;