import React, {
	useState,
	useEffect,
} from "react";

import {
	createUseStyles,
} from "react-jss";

import {
	useQuery,
	// gql,
} from "@apollo/client";

import {
	// motion,
} from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";


import ThemeData from "data/ThemeData";
// import ConstantData from "data/ConstantData";
import QueryData from "data/QueryData";


import useSavedHomes from "hooks/useSavedHomes";
// import preventClickEventPassthrough from "util/preventClickEventsPassthrough";
import UtilFcns from "util/UtilFcns";


// import UnitDetailListItem from "./UnitDetailListItem";
// import UnitDetailCompareCard from "components/UnitDetailCompareCard";
// import SavedHomesTitle from "components/SavedHomesTitle";
import ButtonOutline from "components/ButtonOutline";
import ButtonGroup from "./ButtonGroup";
import CheckedList from "components/CheckedList";
import useGlobalUnitsFilter from "hooks/useGlobalUnitsFilter";


// import chevronLeftWhiteMedium from "img/chevron_left_white_medium.svg";
// import xWhiteMedium from "img/x_white_medium.svg";
// import iconChevronDown from "img/icon_chevron_down_filled_white.svg";
// import SavedHomesCompareList from "./SavedHomesCompareList";


// import { calculateNormals } from "playcanvas";



const useStyles = createUseStyles(
	{
		wrapper: {
			height: '100%',

			// paddingTop: 50,
			// position: 'fixed',
			// top: '50%',
			// left: '50%',
			// paddingTop: 50,

			// width: 'calc( 100vw - 60px )',
			// height: 'calc( 100vh - 200px )',
			// boxSizing: 'border-box',
			// // overflow: 'clip',

			// // padding: '0 35px',

			// color: ThemeData.colours.white,
			// backgroundColor: ThemeData.colours.green,

			// letterSpacing: '.025em',
		},
		filterButtons: {
			padding: '50px 50px 0 50px',

			marginBottom: 50,
		},
		unitCards: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',

			marginTop: 50,

			maxHeight: 'calc( 100% - 145px )',

			// overflowX: 'auto',
			overflowY: 'auto',

			// ...ThemeData.styles.hideScrollbar,
			...ThemeData.styles.thinScrollbar,

			'&.noFilterSort': {
				maxHeight: 'calc( 100% - 50px )',
			},
		},
		bgAmp: {
			zIndex: -1,
			position: 'absolute',
			left: -50,
			bottom: -100,

			color: ThemeData.colours.clay08,
			fontFamily: ThemeData.fonts.gothamBook,
			fontSize: 500,
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',

			width: '100%',
			height: '100%',
		},
		topRightContainer: {
			position: 'absolute',
			top: 60,
			right: 60,

			display: 'flex',
			alignItems: 'center',
		},
		closeX: {
			marginTop: 10,
			marginLeft: 50,

			cursor: 'pointer',
		},
		sendToEmailButton: {
			display: 'inline-block',

			width: 150,

			fontSize: 12,
			letterSpacing: '.1em',
		},
		savedHomesTitle: {
			textAlign: 'left',

			fontSize: 24,
			letterSpacing: '.15em',
		},
		top: {
			flex: '0 0 auto',

			// height: 50,
		},
		addHomesButton: {
			width: 220,

			fontSize: 12,
			letterSpacing: '.1em',

			borderRadius: 25,
		},
		middle: {
			flex: '1 0 auto',
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-evenly',

			maxHeight: 'calc( 100vh - 200px )',

			// overflowX: 'auto',
			overflowY: 'auto',

			// ...ThemeData.styles.hideScrollbar,
			...ThemeData.styles.thinScrollbar,
		},
		unitDetailCompareCardWrapper: {
			flex: '0 0 auto',

			// width: 575,
			// height: '100%',

			padding: '0 10px 40px 20px',
		},
		unitCard: {
			color: ThemeData.colours.black,
		},
		bottom: {
			flex: '0 0 auto',
			display: 'flex',

			boxSizing: 'border-box',
			paddingTop: 25,

			height: 130,

			borderTop: `1px ${ThemeData.colours.white50} solid`,
		},
	},
	{
		name: "UnitCardsParent",
	},
)



// const wrapperAnimVariants = {
// 	open: {
// 		x: '-50%',
// 		y: '-50%',
// 		zIndex: 4,
// 		display: 'block',
// 	},
// 	closed: {
// 		x: "100%",
// 		y: '-50%',
// 		display: 'block',
// 		transitionEnd: {
// 			zIndex: -2,
// 			display: 'none'
// 		},
// 	},
// }





const RenderUnitCardsParent = ( classes, data, filteredUnitsData, error, isOpen, toggleIsOpen, hooks, props ) => {

	// const [isSavedHomesCompareListOpen, setIsSavedHomesCompareListOpen] = useState( false );
	// const toggleIsSavedHomesCompareListOpen = () => setIsSavedHomesCompareListOpen( !isSavedHomesCompareListOpen );

	if ( error ) {
		console.log( "RenderFloorplans", error.message );
	}

	// console.log( 'fud', filteredUnitsData );
	// console.log( 'fcn', props.filterClassName );


	// console.log( 'chill', props.children );
	return (
		// <motion.div 
		// 	className={classes.wrapper}
		// 	// initial={false}
		// 	// animate={isOpen ? "open" : "closed"}
		// 	// variants={wrapperAnimVariants}
		// 	// transition={{
		// 	// 	type: "tween",
		// 	// 	ease: "easeOut",
		// 	// 	duration: .75,
		// 	// }}
		// 	onMouseDown={( e ) => preventClickEventPassthrough( e )}
		// 	onMouseUp={( e ) => preventClickEventPassthrough( e )}
		// >
		// 	<div className={classes.contentContainer}>
		// <div className={classes.top}>
		// 	{/* <div className={classes.savedHomesTitle}>
		// 		Floorplans
		// 	</div> */}
		// </div>
		// <div className={classes.middle}>
		<div className={classes.wrapper}>
			{( !props.dontShowFilter && !props.dontShowFilterSort ) &&
				<ButtonGroup
					className={classes.filterButtons}
					justifyContent={'left'}
				>
					<ButtonOutline
						textColour={ThemeData.colours.green}
						hoverTextColour={ThemeData.colours.clay}
						backgroundColour={ThemeData.colours.white}
						hoverBackgroundColour={ThemeData.colours.clay25}
						borderColour={ThemeData.colours.eggshellTextBg}
						className={classes.filterButton}
						onClick={() => {
						}}
						popupWindow={data &&
							<div className={classes.list}>
								<CheckedList
									className={props.filterClassName}
									data={data.unitHomeTypes}
									listLabelField={'name'}
									// listItemCheckedField={'doFilterBy'}
									filterType={'unitHomeTypes'}
									filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
									setFilterSettings={hooks.globalUnitsFilter.setFilter}
									// filterSettings={hooks.filterSettings}
									// setFilterSettings={hooks.setFilterSettings}
								/>
							</div>
						}
						listItemData={data && data.unitFloors}
						listLabelField={'name'}
						firstInColl

					// isActive={true}
					>
						Rooms &nbsp;<FontAwesomeIcon icon={faCaretDown} />
					</ButtonOutline>
					<ButtonOutline
						textColour={ThemeData.colours.green}
						hoverTextColour={ThemeData.colours.clay}
						backgroundColour={ThemeData.colours.white}
						hoverBackgroundColour={ThemeData.colours.clay25}
						borderColour={ThemeData.colours.eggshellTextBg}
						className={classes.filterButton}
						onClick={() => {
						}}
						popupWindow={data &&
							<div className={classes.list}>
								<CheckedList
									className={props.filterClassName}
									data={data.unitFloors}
									listLabelField={'name'}
									// listItemCheckedField={'doFilterBy'}
									filterType={'unitFloors'}
									filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
									setFilterSettings={hooks.globalUnitsFilter.setFilter}
									// filterSettings={hooks.filterSettings}
									// setFilterSettings={hooks.setFilterSettings}
								/>
							</div>
						}
						listItemData={data && data.unitFloors}
						listLabelField={'name'}

					// isActive={true}
					>
						Floors &nbsp;<FontAwesomeIcon icon={faCaretDown} />
					</ButtonOutline>
					<ButtonOutline
						textColour={ThemeData.colours.green}
						hoverTextColour={ThemeData.colours.clay}
						backgroundColour={ThemeData.colours.white}
						hoverBackgroundColour={ThemeData.colours.clay25}
						borderColour={ThemeData.colours.eggshellTextBg}
						className={classes.filterButton}
						onClick={() => {
						}}

						popupWindow={data &&
							<div className={classes.list}>
								<CheckedList
									className={props.filterClassName}
									data={data.unitExposures}
									listLabelField={'direction'}
									// listItemCheckedField={'doFilterBy'}
									filterType={'unitExposures'}
									filterSettings={hooks.globalUnitsFilter.globalUnitsFilter.filter}
									setFilterSettings={hooks.globalUnitsFilter.setFilter}
									// filterSettings={hooks.filterSettings}
									// setFilterSettings={hooks.setFilterSettings}
								/>
							</div>
						}

						listItemData={data && data.unitExposures}
						listLabelField={'direction'}
					>
						Orientation &nbsp;<FontAwesomeIcon icon={faCaretDown} />
					</ButtonOutline>
					<ButtonOutline
						textColour={ThemeData.colours.green}
						hoverTextColour={ThemeData.colours.clay}
						backgroundColour={ThemeData.colours.white}
						hoverBackgroundColour={ThemeData.colours.clay25}
						borderColour={ThemeData.colours.eggshellTextBg}
						className={classes.filterButton}
						onClick={() => {
						}}

					// listItemData={data && data.unitExposures}
					// listLabelField={'direction'}
					>
						Price &nbsp;<FontAwesomeIcon icon={faCaretDown} />
					</ButtonOutline>
					<ButtonOutline
						textColour={ThemeData.colours.green}
						hoverTextColour={ThemeData.colours.clay}
						backgroundColour={ThemeData.colours.white}
						hoverBackgroundColour={ThemeData.colours.clay25}
						borderColour={ThemeData.colours.eggshellTextBg}
						className={classes.filterButton}
						onClick={() => {
						}}

					// listItemData={data && data.unitSqft}
					// listLabelField={'direction'}
					>
						Size &nbsp;<FontAwesomeIcon icon={faCaretDown} />
					</ButtonOutline>
					<ButtonOutline
						textColour={ThemeData.colours.green}
						hoverTextColour={ThemeData.colours.clay}
						backgroundColour={ThemeData.colours.white}
						hoverBackgroundColour={ThemeData.colours.clay25}
						borderColour={ThemeData.colours.eggshellTextBg}
						className={classes.filterButton}
						onClick={() => {
						}}

					// listItemData={data && data.unitExposures}
					// listLabelField={'direction'}
					>
						Availability &nbsp;<FontAwesomeIcon icon={faCaretDown} />
					</ButtonOutline>
				</ButtonGroup>
			}
			
			<div className={`${classes.unitCards} ${( !props.dontShowFilter && !props.dontShowFilterSort ) ? '' : 'noFilterSort'}`}>
				{props.children && data && data.units && filteredUnitsData && 
					// [...data.units].sort( ( unitA, unitB ) =>
					[...filteredUnitsData].sort( ( unitA, unitB ) =>
						( UtilFcns.parseFloatSafe( unitA.interior_sqft ) + UtilFcns.parseFloatSafe( unitA.exterior_sqft ) ) -
						( UtilFcns.parseFloatSafe( unitB.interior_sqft ) + UtilFcns.parseFloatSafe( unitB.exterior_sqft ) )
					).map( dataUnit => {

						// let unitIdx = data.units.findIndex( elm => elm.id == unit.id );

						// if ( unitIdx != -1 )
						return <div
							className={`${props.childClassName} ${classes.unitDetailCompareCardWrapper}`}
							key={dataUnit.id}
						>
							{props.children(
								classes.unitCard,
								dataUnit,
								() => hooks.toggleSavedHome( dataUnit.id ) )}
							{/* <UnitDetailCompareCard
									className={classes.unitCard}
									data={dataUnit}
									toggleSavedHome={() => hooks.toggleSavedHome( dataUnit.id )}
								/> */}
						</div>;

						// return <></>;

					}
					)}
			</div>
		</div>
		// {/* </div> */}

		// 		{/* <div className={classes.bottom}>
		// 			<ButtonGroup className={classes.bottomButtons}>
		// 				<ButtonOutline
		// 					borderColour={ThemeData.colours.white}
		// 					firstInColl
		// 					className={classes.bottomButton}
		// 				>
		// 					Compare Homes
		// 				</ButtonOutline>
		// 				<ButtonOutline
		// 					borderColour={ThemeData.colours.white}
		// 					className={classes.bottomButton}
		// 				>
		// 					Send To Email
		// 				</ButtonOutline>
		// 			</ButtonGroup>
		// 		</div> */}
		// 		{/* <SavedHomesCompareList
		// 			isOpen={isSavedHomesCompareListOpen}
		// 			toggleIsOpen={() => toggleIsSavedHomesCompareListOpen()}
		// 		/> */}
		// 	{/* </div> */}

		// {/* </motion.div> */}
	)
}



const UnitCardsParent = ( { isOpen, toggleIsOpen, ...props } ) => {

	const classes = useStyles();


	const { savedHomes, toggleSavedHome } = useSavedHomes();

	const [ filterSettings, setFilterSettings ] = useState( {} );

	const globalUnitsFilter = useGlobalUnitsFilter();

	let hooks = {
		savedHomes,
		toggleSavedHome,
		filterSettings,
		setFilterSettings,
		globalUnitsFilter,
	};

	// console.log( "initfs", filterSettings );



	// const { loading, error, data } = useQuery( QueryData.units );
	const { loading, error, data } = useQuery( QueryData.unitProperties );


	const [filteredUnitsData, setFilteredUnitsData] = useState( [] );

	useEffect( () => {
		// console.log( 'filterSettingsChanged', globalUnitsFilter );

		if ( data ) {
			// console.log( "UNfiltereddata", data.units );
			setFilteredUnitsData( globalUnitsFilter.applyFilter( data.units ) );

			// console.log( "Filteredata:", filteredUnitsData );
		}
	}, [ globalUnitsFilter.globalUnitsFilter, globalUnitsFilter, data ] );


	if ( loading ) return RenderUnitCardsParent( classes, null, filteredUnitsData, null, isOpen, toggleIsOpen, hooks, props );
	if ( error ) return RenderUnitCardsParent( classes, null, filteredUnitsData, error, isOpen, toggleIsOpen, hooks, props );

	// console.log( "floorplans", data );

	return RenderUnitCardsParent( classes, data, filteredUnitsData, null, isOpen, toggleIsOpen, hooks, props );

}



UnitCardsParent.defaultProps = {
	dontShowFilterSort: false,
	dontShowFilter: false,
	dontShowSort: false,
}



export default UnitCardsParent;