import React from "react";

import {
	createUseStyles
} from "react-jss";

import {
	useQuery,
	gql,
} from "@apollo/client";


import ThemeData from "../data/ThemeData";
// import NetData from "../data/NetData";


import { sortArrayASC, getCurrPrevNext } from "../util/bidirCollection";


import iconChevronLeft from "../img/icon_chevron_left.svg";
import iconChevronRight from "../img/icon_chevron_right.svg";


const unitPlanTypesQuery = gql`
	query unitPlanTypes {
		unitPlanTypes {
			id
			name
			units {
				unit_home_type {
					name
				}
			}
		}
	}
`;


const useStyles = createUseStyles(
	{
		wrapper: {
			position: 'relative',

			width: '100%',

			color: ThemeData.colours.green,

			fontFamily: ThemeData.fonts.gothamBold,
			fontSize: 24,
		},
		// spinListWrapper: {
		// 	width: '100%',

		// 	overflow: 'hidden',
		// },
		spinList: {
			width: '100%',
			display: 'flex',
			overflowX: 'auto',

			margin: 0,
			padding: 0,

			listStyleType: 'none',

			scrollbarWidth: 'none',

			'&::-webkit-scrollbar': {
				display: 'none',
			},
		},
		iconChevron: {
			position: 'absolute',

			width: 50,
			height: '100%',

			cursor: 'pointer',
		},
		iconChevronLeft: {
			left: -50,

			background: 'no-repeat center',
			backgroundImage: `url(${iconChevronLeft})`
		},
		iconChevronRight: {
			right: -50,

			background: 'no-repeat center',
			backgroundImage: `url(${iconChevronRight})`
		},
		spinListItem: {
			width: 125,

			flex: '1 0 auto',
		},
		notCurrent: {
			opacity: .25,

			cursor: 'pointer',
		},
		itemName: {

		},
		itemHomeType: {
			marginTop: 8,

			fontSize: 10,
		},
	},
	{
		name: "FloorplanSelectionSpinner",
	}
)

function renderUnitPlanTypeItem( unitPlanTypeData, classes, isNotCurrent = false ) {
	return (
		<div className={`${classes.unitPlanTypeItem} ${classes.spinListItem} ${isNotCurrent ? classes.notCurrent : ""}`} key={unitPlanTypeData.id}>
			<div className={classes.itemName}>
				Plan {unitPlanTypeData.name}
			</div>
			<div className={`${classes.itemHomeType}`}>
				{unitPlanTypeData.units && unitPlanTypeData.units[0].unit_home_type.name}
			</div>
		</div>
	);
}

function renderFloorplanSelectionSpinner( selectedUnitPlanTypeArrayIdx, data, sortedUnitPlanTypes, classes, error, floorplanName, toggleUnitDetailPaneFcn, props ) {
	// const doDisplayCard = !( selectedFloorplanId === -1 );

	if ( selectedUnitPlanTypeArrayIdx !== null && selectedUnitPlanTypeArrayIdx !== -1 ) {
		// const apiUri = NetData.apiUri;

		const { currId, prevId, nextId, idArray: currPrevNextIdxs } = getCurrPrevNext( selectedUnitPlanTypeArrayIdx, sortedUnitPlanTypes, true, 1 );

		console.log( "c:", currId, "p:", prevId, "n:", nextId );

		return (
			// <div className={`${classes.wrapper} ${doDisplayCard ? "" : classes.hidden}`}>
			<div className={`${classes.wrapper}`}>
				{/* {floorplanName && `Plan ${floorplanName}`}
				<div className={classes.itemHomeType}>
					{props.floorplanHomeType}
				</div> */}
				<div className={`${classes.iconChevron} ${classes.iconChevronLeft}`}>

				</div>
				<div className={`${classes.iconChevron} ${classes.iconChevronRight}`}>

				</div>
				<div className={classes.spinList}>
					{/* {data && data.unitPlanTypes && data.unitPlanTypes.map( unitPlanType => (
					// <div className={`${classes.unitPlanTypeItem} ${classes.spinListItem}`} key={unitPlanType.id}>
					// 	<div className={classes.itemName}>
					// 		{unitPlanType.name}
					// 	</div>
					// 	<div className={classes.itemHomeType}>
					// 		{unitPlanType.units && unitPlanType.units[0].unit_home_type.name}
					// 	</div>
					// </div> */}
					{currPrevNextIdxs.map( unitPlanTypeIdx => 
						renderUnitPlanTypeItem(
							sortedUnitPlanTypes[unitPlanTypeIdx],
							classes,
							unitPlanTypeIdx !== selectedUnitPlanTypeArrayIdx
						)
					)}
						{/* {renderUnitPlanTypeItem( sortedUnitPlanTypes[prevId], classes, true )}
						{renderUnitPlanTypeItem( sortedUnitPlanTypes[currId], classes )}
						{renderUnitPlanTypeItem( sortedUnitPlanTypes[nextId], classes, true )}
					</> */}
					{/* ))} */}
					{/* {data && data.unitPlanTypes && data.data} */}
				</div>
			</div>
		);
	}

	return null;
}


function FloorplanSelectionSpinner( { selectedFloorplanId, floorplanName, toggleUnitDetailPaneFcn, ...props } ) {

	const classes = useStyles();

	const { loading, error, data } = useQuery( unitPlanTypesQuery, {
	} );


	if ( loading ) return renderFloorplanSelectionSpinner( selectedFloorplanId, null, classes, null, floorplanName, toggleUnitDetailPaneFcn, props );
	if ( error ) return renderFloorplanSelectionSpinner( selectedFloorplanId, null, classes, error, floorplanName, toggleUnitDetailPaneFcn, props );

	// console.log( data );

	let sortedUnitPlanTypes = sortArrayASC( data.unitPlanTypes, "name", false );

	// console.log( "sorted", sortedUnitPlanTypes );

	let selectedUnitPlanTypeArrayIdx = sortedUnitPlanTypes.findIndex( unitPlanType => unitPlanType.id === selectedFloorplanId );

	return renderFloorplanSelectionSpinner( selectedUnitPlanTypeArrayIdx, data, sortedUnitPlanTypes, classes, null, floorplanName, toggleUnitDetailPaneFcn, props );
}



export default FloorplanSelectionSpinner;