import React, {
	useEffect,
	useState,
	useCallback,
	// useRef,
} from "react";

// import useScript from "../hooks/useScript";

import { createUseStyles } from "react-jss";

import {
	motion,
} from "framer-motion";

import Carousel, { Modal, ModalGateway } from "react-images";


import ThemeData from "../data/ThemeData";
import ConstantData from "data/ConstantData";


import Layout from "./Layout";
// import UnitFilter from "./UnitFilter";
import UnitDetailCard from "./UnitDetailCard";
import UnitDetail from "./UnitDetail";
// import ViewStudy from "./ViewStudy";
// import VirtualTour from "./VirtualTour";
import UnitCardSlideout from "components/UnitCardSlideout";
import useOpenClosePane from "hooks/useOpenClosePane";



const useStyles = createUseStyles( {
	canvasWrapper: {
		// height: 'calc( 100vh - 200px )',
		height: 'calc( 100vh - 200px )',

		// backgroundColor: ThemeData.colours.eggshellTextBg,
		backgroundColor: 'white',
	},
} );

function Proximation( { proxima, proximaContainer, ...props } ) {

	const classes = useStyles();

	const [selectedUnitId, setSelectedUnitId] = useState( -1 );

	// const proxima = useRef( null );
	// const proximaContainer = useRef( null );

	// useEffect( () => {
	// 	console.log( 'inHereProx:', proxima );
	// }, [proxima] );
	// useEffect( () => {
	// 	console.log( 'inHereProxCon:', proximaContainer );
	// }, [proximaContainer] );
	useEffect( () => {
		if ( proxima.current && proxima.current.canvas )
			proximaContainer.current.appendChild( proxima.current.canvas );
	} )

	useEffect( () => {
		// let pc = proxima.current.pc;
		// let INPUT_SETTINGS = proxima.current.INPUT_SETTINGS;
		// let CONFIG_FILENAME = proxima.current.CONFIG_FILENAME;
		// let SCENE_PATH = proxima.current.SCENE_PATH;
		// let PRELOAD_MODULES = proxima.current.PRELOAD_MODULES;
		// let loadModules = proxima.current.loadModules;
		// let ASSET_PREFIX = proxima.current.ASSET_PREFIX;

		if ( !proxima.current || !proxima.current.canvas ) {

			proxima.current = {
				pc: window.pc,
				INPUT_SETTINGS: window.INPUT_SETTINGS,
				CONFIG_FILENAME: window.CONFIG_FILENAME,
				SCENE_PATH: window.SCENE_PATH,
				PRELOAD_MODULES: window.PRELOAD_MODULES,
				loadModules: window.loadModules,
				CONTEXT_OPTIONS: window.CONTEXT_OPTIONS,
				ASSET_PREFIX: window.ASSET_PREFIX,
				SCRIPT_PREFIX: window.SCRIPT_PREFIX,
				SCRIPTS: window.SCRIPTS,
				canvas: null,
				devices: null,
				app: null,
			};

			let pc = proxima.current.pc;
			let INPUT_SETTINGS = proxima.current.INPUT_SETTINGS;
			let CONFIG_FILENAME = proxima.current.CONFIG_FILENAME;
			let SCENE_PATH = proxima.current.SCENE_PATH;
			let PRELOAD_MODULES = proxima.current.PRELOAD_MODULES;
			let loadModules = proxima.current.loadModules;
			// let CONTEXT_OPTIONS = proxima.current.CONTEXT_OPTIONS;
			let ASSET_PREFIX = proxima.current.ASSET_PREFIX;
			// let SCRIPT_PREFIX = proxima.current.SCRIPT_PREFIX;
			// let SCRIPTS = proxima.current.SCRIPTS;

			( function () {

				var CANVAS_ID = 'application-canvas';

				// var canvas, devices, app;


				var createCanvas = function () {

					// if ( !canvas ) {
					proxima.current.canvas = document.createElement( 'canvas' );
					// 	doAppendCanvas = true;
					// }

					proxima.current.canvas.setAttribute( 'id', CANVAS_ID );
					proxima.current.canvas.setAttribute( 'tabindex', 0 );
					// canvas.style.visibility = 'hidden';

					// Disable I-bar cursor on click+drag
					proxima.current.canvas.onselectstart = function () { return false; };

					// if ( doAppendCanvas )
					proximaContainer.current.appendChild( proxima.current.canvas );
					// document.getElementById( "canvasWrapper" ).appendChild( proxima.current.canvas );
					// document.body.appendChild(canvas);

					return proxima.current.canvas;
				};

				var createInputDevices = function ( canvas ) {
					var devices = {
						elementInput: new pc.ElementInput( canvas, {
							useMouse: INPUT_SETTINGS.useMouse,
							useTouch: INPUT_SETTINGS.useTouch
						} ),
						keyboard: INPUT_SETTINGS.useKeyboard ? new pc.Keyboard( window ) : null,
						mouse: INPUT_SETTINGS.useMouse ? new pc.Mouse( canvas ) : null,
						gamepads: INPUT_SETTINGS.useGamepads ? new pc.GamePads() : null,
						touch: INPUT_SETTINGS.useTouch && pc.platform.touch ? new pc.TouchDevice( canvas ) : null
					};

					return devices;
				};

				var configureCss = function ( fillMode, width, height ) {
					// Configure resolution and resize event
					if ( proxima.current.canvas.classList ) {
						proxima.current.canvas.classList.add( 'fill-mode-' + fillMode );
					}

					// css media query for aspect ratio changes
					var css = "@media screen and (min-aspect-ratio: " + width + "/" + height + ") {";
					css += "    #application-canvas.fill-mode-KEEP_ASPECT {";
					css += "        width: auto;";
					css += "        height: 100%;";
					css += "        margin: 0 auto;";
					css += "    }";
					css += "}";

					// append css to style
					if ( document.head.querySelector ) {
						document.head.querySelector( 'style' ).innerHTML += css;
					}
				};

				var reflow = function () {
					var canvasWrapper = document.getElementById( "canvasWrapper" );
					if ( canvasWrapper ) {
						// app.resizeCanvas(canvas.width, canvas.height);
						proxima.current.app.resizeCanvas( canvasWrapper.clientWidth, canvasWrapper.clientHeight );
						// console.log( canvasWrapper.clientWidth, canvasWrapper.clientHeight );
						proxima.current.canvas.style.width = '100%';
						proxima.current.canvas.style.height = 'calc( 100vh - 200px )';

						var fillMode = proxima.current.app._fillMode;

						if ( fillMode === pc.FILLMODE_NONE || fillMode === pc.FILLMODE_KEEP_ASPECT ) {
							// if ((fillMode == pc.FILLMODE_NONE && canvas.clientHeight < window.innerHeight) || (canvas.clientWidth / canvas.clientHeight >= window.innerWidth / window.innerHeight)) {
							//     canvas.style.marginTop = Math.floor((window.innerHeight - canvas.clientHeight) / 2) + 'px';
							// } else {
							proxima.current.canvas.style.marginTop = '';
							// }
						}
					}
				};

				var displayError = function ( html ) {
					var div = document.createElement( 'div' );

					div.innerHTML = [
						'<table style="background-color: #8CE; width: 100%; height: 100%;">',
						'  <tr>',
						'      <td align="center">',
						'          <div style="display: table-cell; vertical-align: middle;">',
						'              <div style="">' + html + '</div>',
						'          </div>',
						'      </td>',
						'  </tr>',
						'</table>'
					].join( '\n' );

					document.body.appendChild( div );
				};

				// Check if canvas already exists, don't create it if it does.
				// let doAppendCanvas = false;

				// canvas = document.getElementById( 'canvas' );

				// if ( !canvas ) {
				proxima.current.canvas = createCanvas();
				proxima.current.devices = createInputDevices( proxima.current.canvas );

				try {
					proxima.current.app = new pc.Application( proxima.current.canvas, {
						elementInput: proxima.current.devices.elementInput,
						keyboard: proxima.current.devices.keyboard,
						mouse: proxima.current.devices.mouse,
						gamepads: proxima.current.devices.gamepads,
						touch: proxima.current.devices.touch,
						graphicsDeviceOptions: proxima.current.CONTEXT_OPTIONS,
						assetPrefix: proxima.current.ASSET_PREFIX || "",
						scriptPrefix: proxima.current.SCRIPT_PREFIX || "",
						scriptsOrder: proxima.current.SCRIPTS || []
					} );
				} catch ( e ) {
					if ( e instanceof pc.UnsupportedBrowserError ) {
						displayError( 'This page requires a browser that supports WebGL.<br/>' +
							'<a href="http://get.webgl.org">Click here to find out more.</a>' );
					} else if ( e instanceof pc.ContextCreationError ) {
						displayError( "It doesn't appear your computer can support WebGL.<br/>" +
							'<a href="http://get.webgl.org/troubleshooting/">Click here for more information.</a>' );
					} else {
						displayError( 'Could not initialize application. Error: ' + e );
					}

					return;
				}

				var configure = function () {
					proxima.current.app.configure( CONFIG_FILENAME, function ( err ) {
						const app = proxima.current.app;

						if ( err ) {
							console.error( err );
						}

						configureCss( app._fillMode, app._width, app._height );

						// do the first reflow after a timeout because of
						// iOS showing a squished iframe sometimes
						setTimeout( function () {
							reflow();

							window.addEventListener( 'resize', reflow, false );
							window.addEventListener( 'orientationchange', reflow, false );

							app.preload( function ( err ) {
								if ( err ) {
									console.error( err );
								}

								app.loadScene( SCENE_PATH, function ( err, scene ) {
									if ( err ) {
										console.error( err );
									}

									app.start();
								} );
							} );
						} );
					} );
				};

				if ( PRELOAD_MODULES.length > 0 ) {
					loadModules( PRELOAD_MODULES, ASSET_PREFIX, configure );
				} else {
					configure();
				}


			} )();

			pc.script.createLoadingScreen( function ( app ) {
				var showSplash = function () {
					// splash wrapper
					var wrapper = document.createElement( 'div' );
					wrapper.id = 'application-splash-wrapper';
					document.body.appendChild( wrapper );

					// splash
					var splash = document.createElement( 'div' );
					splash.id = 'application-splash';
					wrapper.appendChild( splash );
					splash.style.display = 'none';

					var logo = document.createElement( 'img' );
					logo.src = ASSET_PREFIX + 'logo.png';
					splash.appendChild( logo );
					logo.onload = function () {
						splash.style.display = 'block';
					};

					var container = document.createElement( 'div' );
					container.id = 'progress-bar-container';
					splash.appendChild( container );

					var bar = document.createElement( 'div' );
					bar.id = 'progress-bar';
					container.appendChild( bar );

				};

				var hideSplash = function () {
					var splash = document.getElementById( 'application-splash-wrapper' );
					splash.parentElement.removeChild( splash );
				};

				var setProgress = function ( value ) {
					var bar = document.getElementById( 'progress-bar' );
					if ( bar ) {
						value = Math.min( 1, Math.max( 0, value ) );
						bar.style.width = value * 100 + '%';
					}
				};

				var createCss = function () {
					var css = [
						'body {',
						`    background-color: ${ThemeData.colours.green};`,
						'}',

						'#application-splash-wrapper {',
						'    position: absolute;',
						'    top: 101px;',
						'    left: 0;',
						'    height: calc( 100vh - 200px );',
						'    width: 100%;',
						`    background-color: ${ThemeData.colours.green};`,
						'}',

						'#application-splash {',
						'    position: absolute;',
						'    top: 46%;',
						'    width: 512px;',
						'    left: 50%;',
						'		 transform: translate(-50%, -50%);',
						'		 -moz-transform: translate(-50%, -50%);',
						'		 -webkit-transform: translate(-50%, -50%);',
						'}',

						'#application-splash img {',
						'    width: 100%;',
						'}',

						'#progress-bar-container {',
						'    margin: 20px auto 0 auto;',
						'    height: 2px;',
						'    width: 100%;',
						'    background-color: #1d292c;',
						'}',

						'#progress-bar {',
						'    width: 0%;',
						'    height: 100%;',
						'    background-color: #f60;',
						'}',
						'@media (max-width: 480px) {',
						'    #application-splash {',
						'        width: 170px;',
						'        left: calc(50% - 85px);',
						'    }',
						'}'

					].join( '\n' );

					var style = document.createElement( 'style' );
					style.type = 'text/css';
					if ( style.styleSheet ) {
						style.styleSheet.cssText = css;
					} else {
						style.appendChild( document.createTextNode( css ) );
					}

					document.head.appendChild( style );
					proximaContainer.current.appendChild( style );
				};


				createCss();

				showSplash();

				app.on( 'preload:end', function () {
					app.off( 'preload:progress' );
				} );
				app.on( 'preload:progress', setProgress );
				app.on( 'start', hideSplash );

				app.on( "api:onBuildingSelected", function ( buildingId, buildingName ) {
					// console.log( "Building ID:", buildingId, "BuildingName: ", buildingName );
					setSelectedUnitId( buildingId );
				} );

				app.on( "app:LOBBY", () => {
					console.log( "EYY got a thing" );
				} );
			} );
		}


	}, [proxima, proximaContainer] );


	let galleryPhotos = [
		{
			src: 'https://storage.googleapis.com/proxima-danielsmpv-prod/MPV_Lobby_Final_148dbecd16/MPV_Lobby_Final_148dbecd16.jpg',
			width: 1777,
			height: 1000,
			webglImageSlug: 'LOBBYENTRANCE',
			title: 'Lobby Entrance',
		},
		{
			src: 'https://storage.googleapis.com/proxima-danielsmpv-prod/MPV_Midrise_Suite514_7b8f3f3b93/MPV_Midrise_Suite514_7b8f3f3b93.jpg',
			width: 1777,
			height: 1000,
			webglImageSlug: 'MIDRISEINTERIORSUITE',
			title: 'Midrise Interior Suite',
		},
		{
			src: 'https://storage.googleapis.com/proxima-danielsmpv-prod/MPV_TH_Suite_Final_V2_0b7a83149c/MPV_TH_Suite_Final_V2_0b7a83149c.jpg',
			width: 1777,
			height: 1000,
			webglImageSlug: 'TOWNHOUSEINTERIORSUITE',
			title: 'Townhouse Interior Suite',
		},
		{
			src: 'https://storage.googleapis.com/proxima-danielsmpv-prod/MPV_Greenspace_Final_6b47cb9ae3/MPV_Greenspace_Final_6b47cb9ae3.jpg',
			width: 1779,
			height: 1000,
			webglImageSlug: 'COMMUNITYENTRANCE',
			title: 'Community Entrance',
		},
		{
			src: 'https://storage.googleapis.com/proxima-danielsmpv-prod/MPV_Midrise_Final_2aedcbb968/MPV_Midrise_Final_2aedcbb968.jpg',
			width: 1777,
			height: 1000,
			webglImageSlug: '6-STOREYMID-RISECONDOMINIUM:BUILDINGA',
			title: '6-Storey Midrise Condominium - Building A',
		},
		{
			src: 'https://storage.googleapis.com/proxima-danielsmpv-prod/MPV_Towns_Final_V2_40e202672b/MPV_Towns_Final_V2_40e202672b.jpg',
			width: 1779,
			height: 1000,
			webglImageSlug: 'CONDOTOWNHOMES:BUILDINGB',
			title: 'Condo Townhomes - Building B',
		},
		{
			src: 'https://storage.googleapis.com/proxima-danielsmpv-prod/MPV_Towns_Final_V2_40e202672b/MPV_Towns_Final_V2_40e202672b.jpg',
			width: 1779,
			height: 1000,
			webglImageSlug: 'CONDOTOWNHOMES:BUILDINGC',
			title: 'Condo Townhomes - Building C',
		},

	]


	const [currentImage, setCurrentImage] = useState( 0 );
	const [isViewerOpen, setIsViewerOpen] = useState( false );

	const openLightbox = useCallback( ( e, { photo, index } ) => {
		// console.log( 'photo', photo, 'index', index );
		setCurrentImage( index );
		setIsViewerOpen( true );
	} );

	const closeLightbox = () => {
		setCurrentImage( 0 );
		setIsViewerOpen( false );
	};





	// Process window events from WebGL
	useEffect( () => {
		const handleHotspotClick = ( e ) => {
			// console.log( e );
			if ( e.origin === window.location.origin &&
				e.data &&
				e.data.message &&
				e.data.message === ConstantData.webglWindowMessageTypes.webglHotspotClicked ) {
				const matchingImgIdx = galleryPhotos.findIndex( galleryPhoto => galleryPhoto.webglImageSlug === e.data.renderId );
				// console.log( matchingImgIdx );
				if ( matchingImgIdx !== -1 ) {
					openLightbox( null, {
						photo: galleryPhotos[matchingImgIdx],
						index: matchingImgIdx,
					} );
				}
			}
		};

		window.addEventListener( "message", handleHotspotClick );
		return () => window.removeEventListener( "message", handleHotspotClick );
	} );



	// useEffect( () => {

	// } ), [];

	// useScript( `${process.env.PUBLIC_URL}/proximation/playcanvas-stable.min.js` );
	// useScript( `${process.env.PUBLIC_URL}/proximation/__settings__.js` );

	// useScript( `${process.env.PUBLIC_URL}/proximation/__modules__.js` );
	// useScript( `${process.env.PUBLIC_URL}/proximation/__start__.js` );
	// useScript( `${process.env.PUBLIC_URL}/proximation/__loading__.js` );

	// window.pc.Application.getApplication().on( "api:onBuildingSelected", function ( buildingId, buildingName ) {
	// 		console.log( "Building ID:", buildingId, "BuildingName: ", buildingName );
	// 	} );

	// window.app.on( "start", alert( "THing" ) );
	const {
		isUnitCardSlideoutOpen,
		// setIsUnitCardSlideoutOpen,
		// toggleUnitCardSlideoutPane: toggleIsUnitCardSlideoutOpen,
		toggleIsUnitCardSlideoutOpen: toggleUnitCardSlideoutPane,
	} = useOpenClosePane( 'UnitCardSlideout' );

	// const [isUnitCardSlideoutOpen, setIsUnitCardSlideoutOpen] = useState( false );
	// const toggleUnitCardSlideoutPane = () => {
	// 	setIsUnitCardSlideoutOpen( !isUnitCardSlideoutOpen );
	// };

	const [isUnitDetailOpen, setIsUnitDetailOpen] = useState( false );
	const toggleUnitDetailPane = () => {
		setIsUnitDetailOpen( !isUnitDetailOpen );
	};

	const [isViewStudyOpen, setIsViewStudyOpen] = useState( false );
	const toggleViewStudyPane = () => {
		setIsViewStudyOpen( !isViewStudyOpen );
	};

	const [isVirtualTourOpen, setIsVirtualTourOpen] = useState( false );
	const toggleVirtualTourPane = () => {
		setIsVirtualTourOpen( !isVirtualTourOpen );
	};

	return (
		<Layout>
			{/* <UnitFilter
				clearSelectedUnitFcn={() => setSelectedUnitId( -1 )}
			/> */}
			<motion.div
				ref={proximaContainer}
				className={classes.canvasWrapper}
				id="canvasWrapper"

				animate={isUnitCardSlideoutOpen ? "partialOpen" : "fullOpen"}
				variants={{
					fullOpen: {
						width: '100vw',
					},
					partialOpen: {
						width: 'calc( 100vw - 790px )',
					},
				}}
				transition={{
					type: 'tween',
					duration: .5,
				}}
			/>
			{/* </motion.div> */}
			{/* <UnitDetailCard
				selectedUnitId={selectedUnitId}
				toggleUnitDetailPaneFcn={() => toggleUnitDetailPane()}
				toggleViewStudyPaneFcn={() => toggleViewStudyPane()}
				toggleVirtualTourPaneFcn={() => toggleVirtualTourPane()}
			/>
			<UnitDetail
				unitId={selectedUnitId}
				isOpen={isUnitDetailOpen}
				toggleUnitDetailFcn={() => toggleUnitDetailPane()}
			/>
			<UnitCardSlideout
				unitId={selectedUnitId}
				isOpen={isUnitCardSlideoutOpen}
				toggleIsOpen={() => toggleUnitCardSlideoutPane()}
			/> */}
			{/* <ViewStudy
				isOpen={isViewStudyOpen}
				toggleViewStudyFcn={() => toggleViewStudyPane()}
			/>
			<VirtualTour
				isOpen={isVirtualTourOpen}
				toggleVirtualTourFcn={() => toggleVirtualTourPane()}
			/> */}
			<ModalGateway>
				{isViewerOpen ? (
					<Modal onClose={closeLightbox}>
						<Carousel
							currentIndex={currentImage}
							views={
								galleryPhotos.map( x => ( {
									...x,
									key: x.src,
									srcset: x.srcset,
									caption: x.title,
								} ) )
							}
						/>
					</Modal>
				) : null}
			</ModalGateway>
		</Layout>
	);
}


export default Proximation;